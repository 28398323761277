import React, { useState } from "react";

import { Formik, Form, Field } from "formik";

import { useRecoilState } from "recoil";

import { Checkbox, FormControlLabel, FormGroup, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";

// @material-ui components
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputAdornment from '@material-ui/core/InputAdornment';
import axios from "axios";
import {
  dialogState,
  handleErrorState,
  loadingOverlayState,
  snackBarState,
} from "../../GlobalAtoms";
import SaveButton from "../../components/Buttons/SaveButton";
import HandleForm from "../../helpers/HandleForm";
import FormBuilder from "../../helpers/FormBuilder";

import { settings, locale, getHeaders } from "../../settings";
import validate from "./validation/buying";
import { ArrowBackIos, ShoppingCart } from "@material-ui/icons";
import DeleteButton from "../../components/Buttons/DeleteButton";

const ConfirmDelivery = () => {
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);

  // eslint-disable-next-line no-unused-vars
  const [checkout, setCheckout] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [dialog, setDialogState] = useRecoilState(dialogState);

  const toggleAgreed = () => {
    setAgreed(!agreed);
  }

  const { initialValues, formGrid, validationRules } = HandleForm(
    [
      {
        label: "name",
        type: "TextField",
        value: "teste",
        validationRules: "string|required",
        size: {
          xs: 12,
          sm: 6,
          lg: 4,
        },
      },
      {
        label: "switch",
        type: "Switch",
        value: false,
        validationRules: "boolean|required",
        size: {
          xs: 12,
          sm: 6,
          lg: 4,
        },
      },
      {
        label: "address",
        type: "TextField",
        value: "testeaaaaa",
        validationRules: "string|required",
        size: {
          xs: 12,
          sm: 12,
          lg: 12,
        },
      },
    ],
    locale.emails.form
  );

  return (
    <Formik
    initialValues={initialValues}
    validationSchema={validate(validationRules)}
    onSubmit={(values, { setSubmitting }) => {
        console.log(values);
        // setLoadingOverlayState(true);
        // setDialogState({
        //   ...dialog,
        //   open: false,
        // });
        // const url = `${settings().apiUrl}/api/emails`;
        // const headers = getHeaders();
        // const body = JSON.stringify({
        //   name: values.name,
        //   address: "fsddsdsd",
        //   checkin: 1,
        //   zipcode: values.zipcode,
        //   phone: values.phone,
        //   region: values.region,
        //   about: values.about,
        //   observations: values.observations,
        //   cost_rate: 1,
        //   company_id: 1,
        //   rate: 1,
        // });

        // axios
        //   .post(url, body, { headers })
        //   .then((response: { data: { data: {} } }) => {
        //     setLoadingOverlayState(false);
        //     const { data } = props;
        //     data.push(response.data.data);
        //     props.setData([...data]);
        //     setSnackBar({
        //       open: true,
        //       type: "success",
        //       message: locale.products.form.add.success,
        //     });
        //     setSubmitting(false);
        //   })
        //   .catch((error) =>
        //     setHandleError({ error: true, response: error.response })
        //   );
    }}
    >
    {({ values, handleChange, submitForm, isSubmitting }) => (
    <Form>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                label="Data"
                id="observations"
                variant="outlined"
                fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            {isSubmitting && <LinearProgress />}
            <Grid item xs={12} sm={12}>
                <Box>
                <SaveButton
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {return false;}}
                />
                </Box>
            </Grid>
        </Grid>
    </Form>
    )}
    </Formik>
  );
};
export default ConfirmDelivery;
