import React from "react";

// @material-ui components
import { Add as AddIcon } from "@material-ui/icons";
import blue from "@material-ui/core/colors/blue";
import { Box, Button, withStyles, makeStyles } from "@material-ui/core";

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: blue[500],
    "&:hover": {
      backgroundColor: blue[700],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
    [theme.breakpoints.down("xs")]: {
      height: 68,
      width: 50,
      borderRadius: 0,
      boxShadow: "none",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 18,
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      fontSize: 23,
    },
  },
}));

const AddButton: React.FC<{ children?: string; onClick: () => void }> = (
  props: any
) => {
  const classes = useStyles();

  return (
    <>
      <Box display={{ xs: "block", sm: "none" }}>
        <ColorButton
          variant="contained"
          disableElevation
          display={{ xs: "block", sm: "none" }}
          {...props}
        >
          <AddIcon className={classes.icon} />
        </ColorButton>
      </Box>
      <Box display={{ xs: "none", sm: "inline" }}>
        <ColorButton
          variant="contained"
          disableElevation
          display={{ xs: "none", sm: "block" }}
          {...props}
        >
          <AddIcon className={classes.icon} /> {props.children}
        </ColorButton>
      </Box>
    </>
  );
};

export default AddButton;
