import getLocale from "./i18n/pt-BR";

interface Headers {
  "Content-Type": "application/json";
  Accept: "application/json";
  "X-Store"?: string;
  "X-Company"?: string;
  Authorization?: string;
  credentials?: boolean;
  "X-XSRF-TOKEN"?: string;
}

const settings = () => ({
  i18n: "pt-BR",
  apiUrl: "http://healthy.test",
});

const locale = getLocale();

const materialTableSettings = () => ({
  localization: locale.materialTable,
  style: { border: "none", boxShadow: "none" },
});

const getHeaders = () => {
  let token = window.localStorage.getItem("ucode");
  const xstore = window.localStorage.getItem("xstore");
  const xcompany = window.localStorage.getItem("xcompany");

  const headers: Headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (token) {
    token = token.substring(1, token.length - 1);
    headers.Authorization = `Bearer ${token}`;
  }

  if (xstore) {
    headers["X-Store"] = xstore;
  }

  if (xcompany) {
    headers["X-Company"] = xcompany;
  }

  return headers;
};

const headers = getHeaders();

const drawerWidth = 280;

export {
  settings,
  locale,
  materialTableSettings,
  getHeaders,
  headers,
  drawerWidth,
};
