import React, { useEffect } from "react";

import { useRecoilState } from "recoil";

import {
  confirmationDialogState,
  handleErrorState,
  loadingOverlayState,
} from "../../GlobalAtoms";

const HandleError = () => {
  const [confirmationDialog, setConfirmationDialogState] = useRecoilState(
    confirmationDialogState
  );

  const [handleError, setHandleError] = useRecoilState(handleErrorState);

  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);

  useEffect(() => {
    if (handleError.response && handleError.error === true) {
      const { status } = handleError.response;
      switch (status) {
        case 401:
          window.localStorage.removeItem("ucode");
          window.localStorage.removeItem("xstore");
          window.localStorage.removeItem("xcompany");
          window.location.href = "/login";
          break;
        case 403:
          setConfirmationDialogState({
            ...confirmationDialog,
            open: true,
            question: `Você não tem autorização para realizar essa ação.`,
            transition: "default",
          });
          setHandleError({
            error: false,
          });
          break;
        default:
          break;
      }
    }
  }, [handleError]);

  return <></>;
};

export default HandleError;
