import React from "react";

import MUIDataTable from "mui-datatables";

// @material-ui components
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const getTheme = () =>
  createTheme({
    overrides: {
      MUIDataTableBodyRow: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: grey[100],
          },
        },
      },
      MUIDataTablePagination: {
        root: {
          borderBottom: "none",
        },
      },
    },
  });

const MainTable: React.FC<{
  data: any[];
  columns: {}[];
}> = (props: any) => (
  <MuiThemeProvider theme={getTheme()}>
    <MUIDataTable
      {...props}
      title={<></>}
      options={{
        responsive: "standard",
        elevation: 0,
        selectableRows: "none",
        filterType: "textField",
      }}
    />
  </MuiThemeProvider>
);

export default MainTable;
