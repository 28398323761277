import React from "react";

// @material-ui components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BackButton from "./Buttons/BackButton";

const useStyles = makeStyles(() => ({
  titleMobile: {
    marginTop: 20,
  },
}));

const MainHeader = (props: { title: JSX.Element; button?: JSX.Element }) => {
  const classes = useStyles();
  return (
    <>
      <Hidden smUp implementation="css">
        <Grid container spacing={0}>
          <Grid item xs={2}>
            <Box textAlign="left">
              <BackButton />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box textAlign="center" className={classes.titleMobile}>
              {props.title}
            </Box>
          </Grid>
          {props.button &&
          <Grid item xs={2}>
            <Box textAlign="right">{props.button}</Box>
          </Grid>
          }
        </Grid>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Grid container spacing={3}>
          <Grid item sm={6}>
            {props.title}
          </Grid>
          <Grid item sm={6}>
            <Box textAlign="right">
              <BackButton /> {props.button}
            </Box>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};

export default MainHeader;
