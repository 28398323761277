import React from "react";

// @material-ui components
import { Button, Box, withStyles, makeStyles } from "@material-ui/core";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import grey from "@material-ui/core/colors/grey";
import { useHistory } from "react-router-dom";

const ColorButton = withStyles((theme) => ({
  root: {
    color: grey[700],
    backgroundColor: grey[300],
    "&:hover": {
      backgroundColor: grey[200],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
    [theme.breakpoints.down("xs")]: {
      height: 68,
      width: 50,
      borderRadius: 0,
      boxShadow: "none",
      backgroundColor: grey[300],
      "&:hover": {
        backgroundColor: grey[500],
      },
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 18,
    [theme.breakpoints.down("xs")]: {
      fontSize: 23,
    },
  },
}));

const BackButton: React.FC = (props) => {
  const classes = useStyles();
  const clientHistory = useHistory();

  return (
    <>
      <Box display={{ xs: "block", sm: "none" }}>
        <ColorButton
          variant="contained"
          disableElevation
          onClick={clientHistory.goBack}
          {...props}
        >
          <ArrowBackIos viewBox="0 0 13 24" className={classes.icon} />
        </ColorButton>
      </Box>
      <Box display={{ xs: "none", sm: "inline" }}>
        <ColorButton
          variant="contained"
          disableElevation
          onClick={clientHistory.goBack}
          {...props}
        >
          <ArrowBackIos className={classes.icon} /> Voltar
        </ColorButton>
      </Box>
    </>
  );
};

export default BackButton;
