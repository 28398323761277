import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// @material-ui components
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
} from "@material-ui/icons";
import {
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Box,
  Divider,
  List,
  Collapse,
  ListSubheader,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

import { drawerWidth } from "../../settings";

import routes from "../../routes";

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    overflowY: "hidden",
  },
  drawerList: {
    paddingTop: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: `1px solid ${grey[300]}`,
    "&*::-webkit-scrollbar": {
      width: 4,
    },
    "&*::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&*::-webkit-scrollbar-thumb": {
      background: grey[400],
      borderRadius: 50,
    },
  },
  mainMenuWrapper: {
    flexDirection: "column",
    minHeight: "100vh",
    display: "flex",
  },
  mainMenu: {
    flexGrow: 1,
  },
  logoContainer: {
    background: "#ffffff",
    textAlign: "center"
  },
  logoImage: {
    width: "80%",
    padding: 20,
  },
  listMainMenu: {
    padding: 16,
    fontSize: 13,
    color: grey[900],
    "&:hover": {
      background: "#266abd",
      color: grey[50],
      cursor: "pointer",
      "& .MuiListItemIcon-root": {
        color: grey[50],
      },
    },
    "& .MuiTypography-root": {
      fontSize: 15,
    },
  },
  listIconMainMenu: {
    minWidth: 35,
  },
  collapsibleMenu: {
    background: grey[50],
  },
  menuSubheader: {
    margin: "15px 0 10px 35px",
    lineHeight: 1,
  },
  listMainSubMenu: {
    whiteSpace: "normal",
    padding: "12px 12px 12px 42px",
    lineHeight: 1,
    minHeight: 1,
    "&:hover": {
      background: grey[200],
    },
  },
  listIconMainSubMenu: {
    minWidth: 16,
    color: grey[600],
    height: 16,
  },
  IconMainSubMenu: {
    height: 16,
    width: 24,
  },
  subMenuItemLabel: {
    fontSize: 14,
    padding: 0,
    margin: 0,
    lineHeight: 1,
    color: grey[800],
  },
}));

const DrawerWrapper: React.FC<{
  open: boolean;
}> = (props) => {
  const customClasses: any = useStyles();

  const [collapsibleMenu, setCollapsibleMenu] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    const itemsMenu: { [key: string]: boolean } = {};
    routes.forEach((route) => {
      if (route.layout === "/dashboard" && route.display === "menu") {
        const menuGroup =
          typeof route.menuGroup !== "undefined"
            ? route.menuGroup
            : "unknownMenu";
        itemsMenu[menuGroup] = false;
      }
    });
    setCollapsibleMenu(itemsMenu);
  }, []);

  const toggleMenuCollapsible = (menuGroup: string | undefined) => {
    const itemsMenu: { [key: string]: boolean } = { ...collapsibleMenu };
    if (typeof menuGroup !== "undefined") {
      itemsMenu[menuGroup] = !itemsMenu[menuGroup];
    }
    setCollapsibleMenu(itemsMenu);
  };

  const drawer = (
    <Box className={customClasses.mainMenuWrapper}>
      <Box className={customClasses.mainMenu}>
        <Box className={customClasses.logoContainer}>
          <img
            src="https://trello.com/1/cards/6206b79f92e77770a4fae940/attachments/621558d5b6049b636f3b755d/download/logo_zinner-removebg-preview.png"
            alt="Grupo Sagitta"
            className={customClasses.logoImage}
          />
        </Box>
        <Divider />
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={customClasses.drawerList}
        >
          {routes.map((route) => {
            if (
              route.display === "menu" &&
              typeof route.menuGroup !== "undefined"
            ) {
              return (
                <>
                  <ListItem
                    onClick={() => toggleMenuCollapsible(route.menuGroup)}
                    className={customClasses.listMainMenu}
                  >
                    <ListItemIcon className={customClasses.listIconMainMenu}>
                      <route.icon />
                    </ListItemIcon>
                    <ListItemText primary={route.label} />
                    {collapsibleMenu[route.menuGroup] ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </ListItem>
                  <Collapse
                    in={collapsibleMenu[route.menuGroup]}
                    timeout="auto"
                    unmountOnExit
                    className={customClasses.collapsibleMenu}
                  >
                    {route.links &&
                      route.links.map((link: any) => {
                        if (link.divider) {
                          return <Divider light />;
                        }
                        if (link.subheader) {
                          return (
                            <ListSubheader
                              className={customClasses.menuSubheader}
                              disableSticky
                            >
                              {link.subheaderTitle}
                            </ListSubheader>
                          );
                        }
                        return (
                          <ListItem
                            component={Link}
                            to={route.layout + link.path}
                            className={customClasses.listMainSubMenu}
                          >
                            <ListItemIcon
                              className={customClasses.listIconMainSubMenu}
                            >
                              <ChevronRightIcon
                                className={customClasses.IconMainSubMenu}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={link.label}
                              className={customClasses.subMenuItemLabel}
                              disableTypography
                            />
                          </ListItem>
                        );
                      })}
                  </Collapse>
                  <Divider light />
                </>
              );
            }
            if (
              route.display === "menu-standalone" &&
              typeof route.menuGroup !== "undefined"
            ) {
              return (
                <>
                  <ListItem
                    component={Link}
                    to={route.layout + route.path}
                    className={customClasses.listMainMenu}
                  >
                    <ListItemIcon className={customClasses.listIconMainMenu}>
                      <route.icon />
                    </ListItemIcon>
                    <ListItemText primary={route.label} />
                  </ListItem>
                  <Divider light />
                </>
              );
            }
            return null;
          })}
        </List>
      </Box>
    </Box>
  );

  return (
    <nav className={customClasses.drawer} aria-label="main-menu">
      <Drawer
        classes={{
          paper: customClasses.drawerPaper,
        }}
        variant="persistent"
        anchor="left"
        open={props.open}
      >
        {drawer}
      </Drawer>
    </nav>
  );
};

export default DrawerWrapper;
