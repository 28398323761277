import React, { useState, useEffect } from "react";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Button, Chip, SvgIconTypeMap, withStyles } from "@material-ui/core";
import axios from "axios";
import { useRecoilState } from "recoil";
import { settings, locale, getHeaders } from "../../settings";
import ContentWrapper from "../../components/ContentWrapper";
import MainHeader from "../../components/MainHeader";
import MainTable from "../../components/MainTable";
import TitleHeader from "../../components/TitleHeader";
import DividerWrapper from "../../components/DividerWrapper";
import ViewButton from "../../components/Buttons/ViewButton";
import ApproveButton from "../../components/Buttons/ApproveButton";
import ReproveButton from "../../components/Buttons/ReproveButton";
import AddButton from "../../components/Buttons/AddButton";
import green from '@material-ui/core/colors/green';
import GetApp from '@material-ui/icons/GetApp';

import BuyingAdd from "./BuyingAdd";
import BuyingEdit from "./BuyingEdit";

import ConfirmDelivery from "./ConfirmDelivery";


import {
  confirmationDialogState,
  dialogState,
  loadingOverlayState,
  snackBarState,
  handleErrorState,
} from "../../GlobalAtoms";

const ConfirmButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
  },
}))(Button);

const ExportButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
  },
}))(Button);


const Buying: React.FC<{
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}> = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [editDialogStatus, setEditDialogStatus] = useState(false);
  const [editDialogData, setEditDialogData] = useState({});
  const [editKey, setEditKey] = useState<number | null>(null);

  // Recoil
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [confirmationDialog, setConfirmationDialogState] = useRecoilState(
    confirmationDialogState
  );
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);

  useEffect(() => {
    const url = `${settings().apiUrl}/api/buyings`;
    const headers = getHeaders();

    setData([    
      {
        id: '111111',
        company: 'Cliente Exemplo',
        store: 'Loja de exemplo',
        date: '23/01/2022 15:48',
        desired_date: '28/01/2022',
        user: 'Felipe Rangel',
        products: 'R$ 345,62',
        delivery_fee: 'R$ 38,96',
        processing_fee: 'R$ 145,62',
        total_fee: 'R$ 545,62',
        observations: 'Observações de exemplo 1',
        status: 'open',
        delivery: '',
      },
      {
        id: '2222222',
        company: 'Cliente Exemplo',
        store: 'Loja de exemplo',
        date: '23/01/2022 15:48',
        desired_date: '28/01/2022',
        user: 'Felipe Rangel',
        products: 'R$ 345,62',
        delivery_fee: 'R$ 38,96',
        processing_fee: 'R$ 145,62',
        total_fee: 'R$ 545,62',
        observations: 'Observações de exemplo 2',
        status: 'cancelled',
        delivery: '',
      },
      {
        id: '333333',
        company: 'Cliente Exemplo',
        store: 'Loja de exemplo',
        date: '23/01/2022 15:48',
        desired_date: '28/01/2022',
        user: 'Helaine Ferreira',
        products: 'R$ 345,62',
        delivery_fee: 'R$ 38,96',
        processing_fee: 'R$ 145,62',
        total_fee: 'R$ 545,62',
        observations: 'Observações de exemplo 3',
        status: 'finalized',
        delivery: '',
      },
      {
        id: '444444444',
        company: 'Cliente Exemplo',
        store: 'Loja de exemplo',
        date: '23/01/2022 15:48',
        desired_date: '28/01/2022',
        user: 'Felipe Rangel',
        products: 'R$ 345,62',
        delivery_fee: 'R$ 38,96',
        processing_fee: 'R$ 145,62',
        total_fee: 'R$ 545,62',
        observations: 'Observações de exemplo 4',
        status: 'finalized',
        delivery: '27/01/2022 08:35',
      },
    ]);
    setLoading(false);

    /*axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoading(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );*/
  }, []);


  const openEditDialog = (rowData: {}, indexKey: number) => {
    console.log('hey');
    setDialogState({
      ...dialog,
      open: true,
      title: "Visualização de Pedido",
      content: editDialog,
      maxWidth: "xl",
      transition: "regular",
    })
  };

  const confirmDelivery = <ConfirmDelivery />;

  const addDialog = <BuyingAdd setData={setData} data={data} />;

  const deleteItem = async (id: number) => {
    setLoadingOverlayState(true);
    let url = `${settings().apiUrl}/api/buyings/${id}`;
    let headers = getHeaders();

    await axios
      .delete(url, { headers })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/buyings`;
    headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoadingOverlayState(false);
        setSnackBar({
          open: true,
          type: "success",
          message: locale.products.form.edit.success,
        });
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  };

  const editDialog = <BuyingEdit />;

  const tableIndexes = locale.buyings.index.table;
  const columns = [
    {
      label: "Numero do pedido",
      name: "id",
    },
    {
      label: "Data do Pedido",
      name: "date",
    },
    {
      label: "Cliente",
      name: "company",
    },
    {
      label: "Loja",
      name: "store",
    },
    {
      label: "Data Pretendida",
      name: "desired_date",
    },
    {
      label: "Usuário",
      name: "user",
    },
    {
      label: "Valor Solicitado",
      name: "products",
    },
    {
      label: "Valor de Entrega",
      name: "delivery_fee",
    },
    {
      label: "Valor de Processamento de Moeda",
      name: "processing_fee",
    },
    {
      label: "Observações",
      name: "observations",
    },
    {
      label: "Status",
      name: "status",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: any,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {value === 'open' && <Chip label="Aberto" style={{background: "#43a047", color: "#fff"}} />}
            {value === 'cancelled' && <Chip label="Cancelado" style={{background: "#e53935", color: "#fff"}} />}
            {value === 'finalized' && <Chip label="Finalizado" style={{background: "#01579b", color: "#fff"}} />}
            {value === 'delivered' && <Chip label="Entregue" style={{background: "#006064", color: "#fff"}} />}
          </>
        ),
      },
    },
    {
      label: "Entrega",
      name: "delivery",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: any,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {value === '' ?
              <ConfirmButton
                variant="contained"
                size="small"
                onClick={() =>
                  setDialogState({
                    ...dialog,
                    open: true,
                    title: "Confirmar Entrega",
                    content: confirmDelivery,
                    maxWidth: "xs",
                    transition: "regular",
                  })
                }
              >
                Confirmar
              </ConfirmButton>
            :
              <>{value}</>
            }
          </>
        ),
      },
    },
    {
      label: "Aprovar",
      name: "id",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            <ApproveButton
            />
          </>
        ),
      },
    },
    {
      label: "Cancelar",
      name: "id",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            <ReproveButton
            />
          </>
        ),
      },
    },
    {
      label: " ",
      name: "id",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            <ViewButton
              onClick={() =>
                openEditDialog(data[tableMeta.rowIndex], tableMeta.rowIndex)
              }
            />
          </>
        ),
      },
    },
    {
      label: " ",
      name: "id",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            <ExportButton
              variant="contained"
              size="small"
            >
              <GetApp style={{fontSize: 18}} />
            </ExportButton>
          </>
        ),
      },
    },
  ];

  return (
    <>
      <ContentWrapper loading={loading}>
        <MainHeader
          title={<TitleHeader>{"Aquisição de Troco"}</TitleHeader>}
        />
        <DividerWrapper />
        <MainTable data={data} columns={columns} />
      </ContentWrapper>
    </>
  );
};

export default Buying;
