import { atom } from "recoil";

// eslint-disable-next-line no-unused-vars
const loadingOverlayState = atom({
  key: "loadingOverlayState",
  default: false,
});

// eslint-disable-next-line no-unused-vars
const snackBarState = atom({
  key: "snackBarState",
  default: {
    open: false,
    type: "success",
    message: "",
  },
});

const dialogState = atom<{
  open: boolean;
  title: boolean | string | JSX.Element;
  content: boolean | string | JSX.Element;
  fullWidth: boolean;
  maxWidth: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
  scroll: "body" | "paper" | undefined;
  fullScreen: boolean | undefined;
  transition: string;
}>({
  key: "dialogState",
  default: {
    open: false,
    title: false,
    content: false,
    fullWidth: true,
    maxWidth: "sm",
    scroll: undefined,
    fullScreen: true,
    transition: "default",
  },
});

const confirmationDialogState = atom<{
  open: boolean;
  question?: string | JSX.Element;
  action?: () => void;
  transition: string;
  type?: string;
}>({
  key: "confirmationDialogState",
  default: {
    open: false,
    transition: "default",
  },
});

const handleErrorState = atom<{
  error: boolean;
  response?: any;
}>({
  key: "handleErrorState",
  default: {
    error: false,
  },
});

export {
  loadingOverlayState,
  snackBarState,
  dialogState,
  confirmationDialogState,
  handleErrorState,
};
