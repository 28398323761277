import * as yup from "yup";

const validate = (fieldsRules: { [key: string]: string }) => {
  const yupValidation: { [key: string]: any } = {};

  Object.keys(fieldsRules).forEach((key) => {
    const rules = fieldsRules[key].split("|");

    /**
     * First, we find the correct Yup schema for this field's rule. If there is no default rule, mixed will be used
     */
    rules.forEach((rule) => {
      switch (rule) {
        case "string":
          yupValidation[key] = yup.string();
          break;

        case "integer":
        case "numeric":
          yupValidation[key] = yup.number();
          break;

        case "boolean":
          yupValidation[key] = yup.boolean();
          break;

        case "date":
          yupValidation[key] = yup.date();
          break;

        default:
          yupValidation[key] = yup.mixed();
          break;
      }
    });

    /**
     * We iterate again the rules to find any subset from what is already set
     */
    rules.forEach((rule) => {
      switch (rule) {
        case "required":
          yupValidation[key] =
            typeof yupValidation[key].required === "function" &&
            yupValidation[key].required();
          break;

        case "integer":
          yupValidation[key] =
            typeof yupValidation[key].integer === "function" &&
            yupValidation[key].integer();
          break;

        default:
          break;
      }
    });
  });

  return yup.object(yupValidation);
};

export default validate;
