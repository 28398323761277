import {
  AccountBox as AccountBoxIcon,
  AttachMoney as AttachMoneyIcon,
  List as ListIcon,
  Description as DescriptionIcon,
  People as PeopleIcon,
  Business as BusinessIcon,
  Assignment as AssignmentIcon,
  FlightTakeoff as FlightTakeoffIcon,
  School as SchoolIcon,
  VerifiedUser as VerifiedUserIcon,
  EventNote as EventNoteIcon,
} from "@material-ui/icons";

import { locale } from "./settings";

// Pages
import Buying from "./pages/Orders/Buying";
import Selling from "./pages/Orders/Selling";
import Companies from "./pages/Company/Companies";
import Stores from "./pages/Company/Stores";
import Emails from "./pages/Company/Emails";
import Users from "./pages/Users/Users";

/**
 * Sets all routes used in dashboard. All routes comes with title, icon and the page's component.
 * Display parameter can be set as 'menu' for main left menu or 'avatar' for avatar's menu
 * All routes for dashboard should be set here, even if it won't be displayed in any menu
 */

const dashboardRoutes = [
  {
    path: "/account",
    label: locale.myAccount.menuTitle,
    icon: AccountBoxIcon,
    component: Buying,
    layout: "/dashboard",
    display: "avatar",
  },
  {
    menuGroup: "pedidos",
    label: "Pedidos",
    icon: DescriptionIcon,
    layout: "/dashboard",
    display: "menu",
    links: [
      {
        path: "/orders/buying",
        label: "Aquisição de Troco",
        component: Buying,
      },
      {
        path: "/orders/selling",
        label: "Recolhimento de Cédulas",
        component: Selling,
      },
    ],
  },
  {
    menuGroup: "cliente",
    label: "Clientes",
    icon: BusinessIcon,
    layout: "/dashboard",
    display: "menu",
    links: [
      {
        path: "/companies",
        label: "Lista de Clientes",
        component: Companies,
      },
      {
        path: "/stores",
        label: "Listagem de Lojas",
        component: Stores,
      },
    ],
  },
  {
    menuGroup: "configuracoes",
    label: "Configurações",
    icon: PeopleIcon,
    layout: "/dashboard",
    display: "menu",
    links: [
      {
        path: "/emails",
        label: "E-mails de Alerta",
        component: Emails,
      },
      {
        path: "/users",
        label: "Lista de Usuários",
        component: Users,
      },
    ],
  },
];

export default dashboardRoutes;
