import { GridSize } from "@material-ui/core/Grid";

interface Sizes {
  xs?: boolean | GridSize;
  sm?: boolean | GridSize;
  md?: boolean | GridSize;
  lg?: boolean | GridSize;
}

interface HandleFormProps {
  label: string;
  type: string;
  value?: boolean | string | number;
  validationRules: string;
  size: Sizes;
}

interface HandleFormReturn {
  initialValues: {
    [key: string]: boolean | string | number | undefined;
  };
  validationRules: { [key: string]: string };
  formGrid: {
    label: string;
    translatedLabel: string | {};
    type: string;
    size: Sizes;
  }[];
}

const HandleForm = (
  props: HandleFormProps[],
  localeIndex: { [key: string]: string | {} }
) => {
  const formData: HandleFormReturn = {
    initialValues: {},
    validationRules: {},
    formGrid: [],
  };

  props.forEach((fieldData) => {
    formData.initialValues[fieldData.label] =
      fieldData.value && fieldData.value;
    formData.validationRules[fieldData.label] =
      fieldData.validationRules && fieldData.validationRules;

    formData.formGrid = [
      ...formData.formGrid,
      {
        label: fieldData.label,
        translatedLabel: localeIndex[fieldData.label],
        type: fieldData.type,
        size: fieldData.size,
      },
    ];
  });

  return formData;
};

export default HandleForm;
