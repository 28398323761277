import React, { useState } from "react";

import { Formik, Form, Field } from "formik";

import { useRecoilState } from "recoil";

import { Checkbox, FormControlLabel, FormGroup, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";

// @material-ui components
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputAdornment from '@material-ui/core/InputAdornment';
import axios from "axios";
import {
  dialogState,
  handleErrorState,
  loadingOverlayState,
  snackBarState,
} from "../../GlobalAtoms";
import SaveButton from "../../components/Buttons/SaveButton";
import HandleForm from "../../helpers/HandleForm";
import FormBuilder from "../../helpers/FormBuilder";

import { settings, locale, getHeaders } from "../../settings";
import validate from "./validation/buying";
import { ArrowBackIos, ShoppingCart } from "@material-ui/icons";
import DeleteButton from "../../components/Buttons/DeleteButton";

const UserAdd: React.FC<{
  setData: React.Dispatch<any>;
  data: {}[];
}> = () => {
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);

  // eslint-disable-next-line no-unused-vars
  const [checkout, setCheckout] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [agreed2, setAgreed2] = useState(false);
  const [agreed3, setAgreed3] = useState(false);
  const [agreed4, setAgreed4] = useState(false);
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [dialog, setDialogState] = useRecoilState(dialogState);

  const toggleAgreed2 = () => {
    setAgreed2(!agreed2);
  }
  const toggleAgreed3 = () => {
    setAgreed3(!agreed3);
  }
  const toggleAgreed4 = () => {
    setAgreed4(!agreed4);
  }

  const { initialValues, formGrid, validationRules } = HandleForm(
    [
      {
        label: "name",
        type: "TextField",
        value: "teste",
        validationRules: "string|required",
        size: {
          xs: 12,
          sm: 6,
          lg: 4,
        },
      },
      {
        label: "switch",
        type: "Switch",
        value: false,
        validationRules: "boolean|required",
        size: {
          xs: 12,
          sm: 6,
          lg: 4,
        },
      },
      {
        label: "address",
        type: "TextField",
        value: "testeaaaaa",
        validationRules: "string|required",
        size: {
          xs: 12,
          sm: 12,
          lg: 12,
        },
      },
    ],
    locale.buyings.form
  );


  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validate(validationRules)}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          // setLoadingOverlayState(true);
          // setDialogState({
          //   ...dialog,
          //   open: false,
          // });
          // const url = `${settings().apiUrl}/api/buyings`;
          // const headers = getHeaders();
          // const body = JSON.stringify({
          //   name: values.name,
          //   address: "fsddsdsd",
          //   checkin: 1,
          //   zipcode: values.zipcode,
          //   phone: values.phone,
          //   region: values.region,
          //   about: values.about,
          //   observations: values.observations,
          //   cost_rate: 1,
          //   company_id: 1,
          //   rate: 1,
          // });

          // axios
          //   .post(url, body, { headers })
          //   .then((response: { data: { data: {} } }) => {
          //     setLoadingOverlayState(false);
          //     const { data } = props;
          //     data.push(response.data.data);
          //     props.setData([...data]);
          //     setSnackBar({
          //       open: true,
          //       type: "success",
          //       message: locale.products.form.add.success,
          //     });
          //     setSubmitting(false);
          //   })
          //   .catch((error) =>
          //     setHandleError({ error: true, response: error.response })
          //   );
        }}
      >
        {({ values, handleChange, submitForm, isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField id="outlined-basic1" label="Nome" fullWidth variant="outlined" value="Mc Donald's" />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField id="outlined-basic1" label="Sigla" fullWidth variant="outlined" value="MCD" />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField id="outlined-basic3" label="Razão Sozial" fullWidth variant="outlined" value="Mc Donlad's Alimentos" />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField id="outlined-basic2" label="CNPJ" fullWidth variant="outlined" value="44.555/0001-22" />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField id="outlined-basic2" label="Responsável" fullWidth variant="outlined" value="Felipe Rangel" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <h3 style={{margin: 0}}>Endereço:</h3>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField id="outlined-basic1" label="Endereço" fullWidth variant="outlined" value="Endereço Exemplo" />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField id="outlined-basic2" label="N.:" fullWidth variant="outlined" value="Valor Exemplo" />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField id="outlined-basic1" label="Complemento" fullWidth variant="outlined" value="Valor Exemplo" />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField id="outlined-basic2" label="Cidade" fullWidth variant="outlined" value="Valor Exemplo" />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField id="outlined-basic2" label="CEP" fullWidth variant="outlined" value="Valor Exemplo" />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField id="outlined-basic2" label="UF" fullWidth variant="outlined" value="Valor Exemplo" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <h3 style={{margin: 0}}>Serviços Disponíveis:</h3>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={true} onChange={() => {return false;}} name="checkedA" color="primary" />}
                      label="Aquisição de Troco"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField id="outlined-basic1" label="Valor do Frete" fullWidth variant="outlined" value="Endereço Exemplo" />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField id="outlined-basic2" label="Custo do Milheiro" fullWidth variant="outlined" value="Valor Exemplo" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={true} onChange={() => {return false;}} name="checkedA" color="primary" />}
                      label="Recolhimento de Cédulas"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField id="outlined-basic1" label="Valor do Frete" fullWidth variant="outlined" value="Endereço Exemplo" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <h3 style={{margin: 0}}>Usuário Administrador:</h3>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField id="outlined-basic2" label="Nome" fullWidth variant="outlined" value="Valor Exemplo" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField id="outlined-basic2" label="E-mail" fullWidth variant="outlined" value="Valor Exemplo" />
            </Grid>
            {isSubmitting && <LinearProgress />}
            <Grid item xs={12} sm={12}>
                <Box>
                <SaveButton
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {return false;}}
                />
                </Box>
            </Grid>
          </Grid>
        </Form>
        )}
      </Formik>
    </>
  );
};
export default UserAdd;
