import React from "react";

// @material-ui components
import { Button, withStyles } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import teal from "@material-ui/core/colors/teal";

const ColorButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: teal[500],
    "&:hover": {
      backgroundColor: teal[700],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
  },
}))(Button);

const ViewButton: React.FC<{ children?: string; onClick: () => void }> = (
  props
) => (
  <ColorButton variant="contained" size="small" {...props}>
    <Visibility style={{ fontSize: 18 }} /> {props.children}
  </ColorButton>
);

export default ViewButton;
