import React from "react";

// @material-ui components
import { Button, withStyles } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import red from "@material-ui/core/colors/red";

const ColorButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
  },
}))(Button);

const DeleteButton: React.FC<{
  onClick: () => void;
  style?: any;
}> = (props) => (
  <ColorButton variant="contained" size="small" {...props}>
    {props.children ? <>{props.children}</> : <><Delete style={{ fontSize: 18, marginRight: 0 }} /></>}
  </ColorButton>
);

export default DeleteButton;
