import React, { useState } from "react";

import { Formik, Form, Field } from "formik";

import { useRecoilState } from "recoil";

import { Checkbox, FormControlLabel, FormGroup, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";

// @material-ui components
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputAdornment from '@material-ui/core/InputAdornment';
import axios from "axios";
import {
  dialogState,
  handleErrorState,
  loadingOverlayState,
  snackBarState,
} from "../../GlobalAtoms";
import SaveButton from "../../components/Buttons/SaveButton";
import HandleForm from "../../helpers/HandleForm";
import FormBuilder from "../../helpers/FormBuilder";

import { settings, locale, getHeaders } from "../../settings";
import validate from "./validation/buying";
import { ArrowBackIos, ShoppingCart } from "@material-ui/icons";
import DeleteButton from "../../components/Buttons/DeleteButton";

const UserEdit: React.FC<{}> = () => {
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);

  // eslint-disable-next-line no-unused-vars
  const [checkout, setCheckout] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [agreed2, setAgreed2] = useState(false);
  const [agreed3, setAgreed3] = useState(false);
  const [agreed4, setAgreed4] = useState(false);
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [dialog, setDialogState] = useRecoilState(dialogState);

  const toggleAgreed2 = () => {
    setAgreed2(!agreed2);
  }
  const toggleAgreed3 = () => {
    setAgreed3(!agreed3);
  }
  const toggleAgreed4 = () => {
    setAgreed4(!agreed4);
  }

  const { initialValues, formGrid, validationRules } = HandleForm(
    [
      {
        label: "name",
        type: "TextField",
        value: "teste",
        validationRules: "string|required",
        size: {
          xs: 12,
          sm: 6,
          lg: 4,
        },
      },
      {
        label: "switch",
        type: "Switch",
        value: false,
        validationRules: "boolean|required",
        size: {
          xs: 12,
          sm: 6,
          lg: 4,
        },
      },
      {
        label: "address",
        type: "TextField",
        value: "testeaaaaa",
        validationRules: "string|required",
        size: {
          xs: 12,
          sm: 12,
          lg: 12,
        },
      },
    ],
    locale.buyings.form
  );


  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validate(validationRules)}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          // setLoadingOverlayState(true);
          // setDialogState({
          //   ...dialog,
          //   open: false,
          // });
          // const url = `${settings().apiUrl}/api/buyings`;
          // const headers = getHeaders();
          // const body = JSON.stringify({
          //   name: values.name,
          //   address: "fsddsdsd",
          //   checkin: 1,
          //   zipcode: values.zipcode,
          //   phone: values.phone,
          //   region: values.region,
          //   about: values.about,
          //   observations: values.observations,
          //   cost_rate: 1,
          //   company_id: 1,
          //   rate: 1,
          // });

          // axios
          //   .post(url, body, { headers })
          //   .then((response: { data: { data: {} } }) => {
          //     setLoadingOverlayState(false);
          //     const { data } = props;
          //     data.push(response.data.data);
          //     props.setData([...data]);
          //     setSnackBar({
          //       open: true,
          //       type: "success",
          //       message: locale.products.form.add.success,
          //     });
          //     setSubmitting(false);
          //   })
          //   .catch((error) =>
          //     setHandleError({ error: true, response: error.response })
          //   );
        }}
      >
        {({ values, handleChange, submitForm, isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nome"
                id="desired_date"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="E-mail"
                id="observations"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <h3 style={{margin: 0}}>Grupos de Permissões:</h3>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={3}>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox checked={agreed} onChange={()=> {return false;}} name="checked" color="primary" />}
                  label={'Administrador'}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={3}>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox checked={agreed2} onChange={toggleAgreed2} name="checked2" color="primary" />}
                  label={'Grupo 1'}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={3}>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox checked={agreed3} onChange={toggleAgreed3} name="checked3" color="primary" />}
                  label={'Grupo 2'}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={3}>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox checked={agreed4} onChange={toggleAgreed4} name="checked4" color="primary" />}
                  label={'Grupo 3'}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {isSubmitting && <LinearProgress />}
            <Grid item xs={12} sm={12}>
              <Box>
                <SaveButton
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => setCheckout(true)}
               />
              </Box>
            </Grid>
          </Grid>
        </Form>
        )}
      </Formik>
    </>
  );
};
export default UserEdit;
