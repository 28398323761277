import React from "react";

// @material-ui components
import { Button, withStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import red from "@material-ui/core/colors/red";

const ColorButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
  },
}))(Button);

const ReproveButton: React.FC<{ children?: string }> = (
  props
) => (
  <ColorButton variant="contained" size="small" {...props}>
    <Close style={{ fontSize: 18 }} /> {props.children}
  </ColorButton>
);

export default ReproveButton;
