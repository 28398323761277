import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import axios from "axios";

import { Formik, Form, Field } from "formik";

import { useRecoilState } from "recoil";

// @material-ui components
import {
  Divider,
  LinearProgress,
  CssBaseline,
  Hidden,
  Grid,
  Box,
} from "@material-ui/core";

import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Mail as MailIcon,
  ExitToApp as ExitToAppIcon,
  Twitter as TwitterIcon,
  YouTube as YouTubeIcon,
} from "@material-ui/icons";
import { indigo, red } from "@material-ui/core/colors";

import DialogWrapper from "../components/Layouts/DialogWrapper";
import TextFieldWrapper from "../components/Form/TextFieldWrapper";
import AsyncLocalStorage from "../helpers/AsyncLocalStorage";
import { settings, getHeaders, locale } from "../settings";

import { dialogState } from "../GlobalAtoms";
import LoginButton from "../components/Buttons/LoginButton";

interface Values {
  email: string;
  password: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    minHeight: "100vh",
    overflow: "auto",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0),
    },
  },
  contentLogin: {
    padding: theme.spacing(0, 5),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 10),
    },
    [theme.breakpoints.up("md")]: {
      height: "100vh",
      minHeight: "100vh",
      padding: theme.spacing(8),
    },
  },
  imgLeft: {
    height: "100vh",
    minHeight: "100vh",
    transform: "scaleX(-1)",
    background: "no-repeat center/auto 100% #444 url(https://trello.com/1/cards/6206b79f92e77770a4fae940/attachments/6206e21b6def8871071049a0/previews/6206e21c6def8871071049ae/download/growth-wealth-finance-money-money-currency-currency-stacked-coins-cash-payment-bitcoin-crypto_t20_GJK28Y.jpg)",
  },
  formControlLabelCheckbox: {
    marginTop: theme.spacing(3),
  },
  buttonContainer: {
    margin: "24px 0px 16px 0px",
  },
  imgContainer: {
    margin: "0px 0px 16px 0px",
    [theme.breakpoints.down("xs")]: {
      margin: "40px 0px 16px 0px",
    },
  },
  forgotPasswordContainer: {
    margin: "10px 0px 16px 0px",
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0px 16px 0px",
    },
  },
  forgotPasswordSpan: {
    color: indigo[700],
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  socialMediaContainer: {
    margin: "0px 0px 16px 0px",
    [theme.breakpoints.down("xs")]: {
      margin: "15px 0px 16px 0px",
    },
  },
  errorLogin: {
    color: red[800],
    fontSize: 12,
    marginTop: 13,
  },
}));

const Login = () => {
  const customClasses = useStyles();
  // Recoil
  const [dialog, setDialogState] = useRecoilState(dialogState);
  // const [dialog, setDialogState] = useRecoilState(dialogState);
  const [errorLogin, setErrorLogin] = useState({ active: false, message: "" });
  // eslint-disable-next-line no-unused-vars
  const [errorForgotPassword, setErrorForgotPassword] = useState({
    active: false,
    message: "",
  });
  const [loggedIn, setLoggedIn] = useState(false);
  // const [loadingPasswordRecovery, setLoadingPasswordRecovery] = useState(false);

  useEffect(() => {
    const token = window.localStorage.getItem("ucode");
    const xstore = window.localStorage.getItem("xstore");
    const xcompany = window.localStorage.getItem("xcompany");
    if (token && xstore && xcompany) {
      setLoggedIn(true);
    }
  }, []);

  const forgotPasswordContent = (
    <>
      <Formik
        initialValues={{
          email: "",
        }}
        validate={(values) => {
          const errors: Partial<Values> = {};
          if (!values.email) {
            errors.email = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
          }, 5000);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  label={locale.login.email}
                  name="email"
                  component={TextFieldWrapper}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Divider />
              </Grid>
              {isSubmitting && <LinearProgress />}
              <Grid item xs={12} sm={12}>
                <LoginButton
                  type="submit"
                  disabled={isSubmitting}
                  icon={MailIcon}
                >
                  Recuperar senha
                </LoginButton>
                <Box textAlign="center" className={customClasses.errorLogin}>
                  {errorForgotPassword.active && errorForgotPassword.message}
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );

  const openForgotPasswordDialog = () => {
    setDialogState({
      ...dialog,
      open: true,
      title: "Recuperar Senha",
      content: forgotPasswordContent,
      maxWidth: "xs",
      transition: "regular",
    });
  };

  const content = (
    <>
      {loggedIn && <Redirect to="/dashboard" />}
      <Grid container justify="center" spacing={3}>
        <Grid item xs={8} sm={7} md={6}>
          <Box textAlign="center" className={customClasses.imgContainer}>
            <img
              src="https://trello.com/1/cards/6206b79f92e77770a4fae940/attachments/621558d5b6049b636f3b755d/download/logo_zinner-removebg-preview.png"
              alt="Grupo Sagitta"
              style={{ width: "100%" }}
            />
          </Box>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validate={(values) => {
          const errors: Partial<Values> = {};
          if (!values.email) {
            errors.email = "Required";
          }
          if (!values.password) {
            errors.password = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          const headers = getHeaders();

          headers.credentials = true;

          const body = JSON.stringify({
            email: values.email,
            password: values.password,
            device_name: "admin-browser",
          });


          setLoggedIn(true);

          /*axios
            .post(`${settings().apiUrl}/api/login`, body, { headers })
            .then((responseLogin) => {
              AsyncLocalStorage(
                "ucode",
                JSON.stringify(responseLogin.data)
              ).then(() => {
                headers.Authorization = `Bearer ${responseLogin.data}`;
                axios
                  .get(`${settings().apiUrl}/api/stores`, { headers })
                  .then((responseSetCompany) => {
                    const { data } = responseSetCompany.data;
                    AsyncLocalStorage("xstore", data[0].id).then(() => {
                      AsyncLocalStorage("store_name", data[0].name).then(() => {
                      });
                    });
                  })
                  .catch(() => {
                    setErrorLogin({
                      active: true,
                      message: locale.login.noCompany,
                    });
                  });
              });
            })
            .catch(() => {
              setErrorLogin({
                active: true,
                message: locale.login.loginError,
              });
            });
            */
          setSubmitting(false);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field
                  label={locale.login.email}
                  name="email"
                  component={TextFieldWrapper}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label={locale.login.password}
                  name="password"
                  component={TextFieldWrapper}
                  type="password"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Divider />
              </Grid>
              {isSubmitting && <LinearProgress />}
              <Grid item xs={12} sm={12}>
                <Box>
                  <LoginButton
                    type="submit"
                    disabled={isSubmitting}
                    icon={ExitToAppIcon}
                  >
                    Logar
                  </LoginButton>
                </Box>
                <Box textAlign="center" className={customClasses.errorLogin}>
                  {errorLogin.active && errorLogin.message}
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Box textAlign="center" className={customClasses.forgotPasswordContainer}>
        <span
          onClick={() => openForgotPasswordDialog()}
          onKeyDown={() => openForgotPasswordDialog()}
          role="button"
          tabIndex={0}
          className={customClasses.forgotPasswordSpan}
        >
          {locale.login.forgotPasswordTrigger}
        </span>
      </Box>
    </>
  );

  return (
    <div className={customClasses.root}>
      <CssBaseline />
      <main className={customClasses.content}>
        <Hidden smUp implementation="css">
          <Box className={customClasses.contentLogin} />
          {content}
        </Hidden>
        <Hidden xsDown implementation="css">
          <Grid container spacing={0}>
            <Grid item sm={5} md={7} xl={8} className={customClasses.imgLeft} />
            <Grid
              item
              sm={7}
              md={5}
              xl={4}
              className={customClasses.contentLogin}
            >
              {content}
            </Grid>
          </Grid>
        </Hidden>
        <DialogWrapper />
      </main>
    </div>
  );
};

export default Login;
