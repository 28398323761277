/** PROTOTYPED */
const getLocale = () => ({
  miscellanea: {
    back: "Voltar",
    save: "Salvar",
    edit: "Editar",
    yes: "Sim",
    cancel: "Cancelar",
    add: "Adicionar",
  },

  weekDay: {
    Sunday: "Domingo",
    Monday: "Segunda-feira",
    Tuesday: "Terça-feira",
    Wednesday: "Quarta-feira",
    Thursday: "Quinta-feira",
    Friday: "Sexta-feira",
    Saturday: "Sábado",
  },

  links: {
    portal: "Acesso ao Portal",
    payments: "Painel de Pagamentos",
  },

  appBar: {
    avatarLogout: "Sair",
  },

  materialTable: {
    toolbar: {
      searchTooltip: "Procurar",
      searchPlaceholder: "Procurar",
    },
  },

  login: {
    noStore: "Seu usuário não está associado a nenhuma loja",
    noCompany: "Seu usuário não está associado a nenhuma empresa",
    loginError: "Usuário ou senha inválidos",
    email: "E-mail",
    password: "Senha",
    keepLoggedOn: "Manter conectado",
    loginButton: "Login",
    forgotPasswordTrigger: "Esqueceu sua senha?",
  },

  mySignature: {
    menuTitle: "Minha assinatura",
  },

  companyData: {
    menuTitle: "Dados da empresa",
  },

  myAccount: {
    menuTitle: "Minha conta",
  },

  accountSettings: {
    menuTitle: "Configurações Gerais",
  },

  support: {
    menuTitle: "Dúvidas & Suporte",
  },

  changePassword: {
    menuTitle: "Atualizar senha",
  },

  orders: {
    menuTitle: "Pedidos",
  },

  settings: {
    menuTitle: "Administrar",
  },

  discounts: {
    menuTitle: "Descontos",
  },

  clients: {
    menuTitle: "Clientes",
  },

  reviews: {
    menuTitle: "Avaliações",
  },

  reservation: {
    menuTitle: "Reservas",
  },

  statistics: {
    menuTitle: "Estatisticas",
  },

  products: {
    menuTitle: "Produtos",
    index: {
      menuTitle: "Lista de produtos",
      title: "Produtos",
      table: {
        name: "Nome",
        sku: "Abreviação",
        description: "Descrição",
        price: "Preço",
        sale_price: "Preço de venda",
        status: "Status",
      },
      toggleEnable: {
        setTrue: {
          title: "Desja ativar esta produto?",
          content:
            "Todos os grupos de opções deste produto serão exibidos no client de pedidos",
        },
      },
      add: "Adicionar produto",
      edit: "Editar produto",
    },
    add: {
      menuTitle: "Adicionar produtos",
      title: "Adicionar produtos",
    },
    edit: {
      menuTitle: "Editar produto",
      title: "Editar produto",
      editTabs: {
        main: {
          menuTitle: "Editar produto",
        },
        associatedVariants: {
          menuTitle: "Grupos de opções associados",
        },
        associatedCategories: {
          menuTitle: "Categorias associadas",
        },
        associatedImages: {
          menuTitle: "Imagens associadas",
        },
        associatedAvailabilities: {
          menuTitle: "Dias da semana associados",
          edit: {
            success: "Dias da semana editados com sucesso",
            error:
              "Não foi possível salvar, verifique o preenchimento das datas.",
          },
          table: {
            day: "Dia",
            start_time: "Hora inicial",
            end_time: "Hora final",
            status: "Ativo?",
          },
        },
      },
    },
    form: {
      add: {
        success: "Produto cadastrado com sucesso",
        error: "Não foi possível cadastrar o produto",
      },
      edit: {
        success: "Produto editado com sucesso",
        error: "Não foi possível editar o produto",
      },
      toggleEnabled: {
        enableSuccess: "Produto ativado com sucesso",
        enableError: "Não foi possível ativar o produto",
        disableSuccess: "Produto desativado com sucesso",
        disableError: "Não foi possível desativar o produto",
      },
      uploadImages: {
        imageUploaded: "Imagem salva com sucesso",
        imageDeleted: "Imagem deletada com sucesso",
      },
      name: "Nome",
      sku: "Abreviação",
      description: "Descrição",
      price: "Preço",
      sale_price: "Preço de venda",
      is_enabled: "Status",
    },
  },

  categories: {
    menuTitle: "Categorias",
    index: {
      menuTitle: "Lista de categorias",
      title: "Categorias",
      table: {
        name: "Nome",
        status: "Status",
      },
      toggleEnable: {
        setTrue: {
          title: "Desja ativar esta categoria?",
          content:
            "Todos os produtos desta categoria serão exibidos no client de pedidos",
        },
      },
      add: "Adicionar categoria",
      edit: "Editar categoria",
      editTabs: {
        main: "Editar categoria",
        associatedProducts: "Produtos associados",
      },
    },
    add: {
      menuTitle: "Adicionar categorias",
      title: "Adicionar categorias",
    },
    edit: {
      menuTitle: "Editar categoria",
      title: "Editar categoria",
    },
    form: {
      add: {
        success: "Categoria cadastrada com sucesso",
        error: "Não foi possível cadastrar a categoria",
      },
      edit: {
        success: "Categoria editada com sucesso",
        error: "Não foi possível editar a categoria",
      },
      toggleEnabled: {
        enableSuccess: "Categoria ativada com sucesso",
        enableError: "Não foi possível ativar a categoria",
        disableSuccess: "Categoria desativada com sucesso",
        disableError: "Não foi possível desativar a categoria",
      },
      name: "Nome",
      is_enabled: "Status",
    },
  },

  buyings: {
    menuTitle: "Lojas",
    index: {
      menuTitle: "Lista de lojas",
      title: "Lojas",
      table: {
        name: "name",
        zipcode: "zipcode",
        address: "address",
        phone: "phone",
        region: "region",
        about: "about",
        observations: "observations",
      },
      toggleEnable: {
        setTrue: {
          title: "Desja ativar esta loja?",
          content: "Mensagem padrao ao ativar.",
        },
      },
      add: "Adicionar loja",
      edit: "Editar loja",
      editTabs: {
        main: "Editar loja",
        associatedProducts: "Produtos associados",
      },
    },
    add: {
      menuTitle: "Adicionar lojas",
      title: "Adicionar lojas",
    },
    edit: {
      menuTitle: "Editar loja",
      title: "Editar loja",
    },
    form: {
      add: {
        success: "Loja cadastrada com sucesso",
        error: "Não foi possível cadastrar a loja",
      },
      edit: {
        success: "Loja editada com sucesso",
        error: "Não foi possível editar a loja",
      },
      toggleEnabled: {
        enableSuccess: "Loja ativada com sucesso",
        enableError: "Não foi possível ativar a loja",
        disableSuccess: "Loja desativada com sucesso",
        disableError: "Não foi possível desativar a loja",
      },
      name: "name",
      switch: "switch",
      zipcode: "zipcode",
      address: "address",
      phone: "phone",
      region: "region",
      about: "about",
      observations: "observations",
    },
  },

  sellings: {
    menuTitle: "Lojas",
    index: {
      menuTitle: "Lista de lojas",
      title: "Lojas",
      table: {
        name: "name",
        zipcode: "zipcode",
        address: "address",
        phone: "phone",
        region: "region",
        about: "about",
        observations: "observations",
      },
      toggleEnable: {
        setTrue: {
          title: "Desja ativar esta loja?",
          content: "Mensagem padrao ao ativar.",
        },
      },
      add: "Adicionar loja",
      edit: "Editar loja",
      editTabs: {
        main: "Editar loja",
        associatedProducts: "Produtos associados",
      },
    },
    add: {
      menuTitle: "Adicionar lojas",
      title: "Adicionar lojas",
    },
    edit: {
      menuTitle: "Editar loja",
      title: "Editar loja",
    },
    form: {
      add: {
        success: "Loja cadastrada com sucesso",
        error: "Não foi possível cadastrar a loja",
      },
      edit: {
        success: "Loja editada com sucesso",
        error: "Não foi possível editar a loja",
      },
      toggleEnabled: {
        enableSuccess: "Loja ativada com sucesso",
        enableError: "Não foi possível ativar a loja",
        disableSuccess: "Loja desativada com sucesso",
        disableError: "Não foi possível desativar a loja",
      },
      name: "name",
      switch: "switch",
      zipcode: "zipcode",
      address: "address",
      phone: "phone",
      region: "region",
      about: "about",
      observations: "observations",
    },
  },

  emails: {
    menuTitle: "Lojas",
    index: {
      menuTitle: "Lista de lojas",
      title: "Lojas",
      table: {
        name: "name",
        zipcode: "zipcode",
        address: "address",
        phone: "phone",
        region: "region",
        about: "about",
        observations: "observations",
      },
      toggleEnable: {
        setTrue: {
          title: "Desja ativar esta loja?",
          content: "Mensagem padrao ao ativar.",
        },
      },
      add: "Adicionar loja",
      edit: "Editar loja",
      editTabs: {
        main: "Editar loja",
        associatedProducts: "Produtos associados",
      },
    },
    add: {
      menuTitle: "Adicionar lojas",
      title: "Adicionar lojas",
    },
    edit: {
      menuTitle: "Editar loja",
      title: "Editar loja",
    },
    form: {
      add: {
        success: "Loja cadastrada com sucesso",
        error: "Não foi possível cadastrar a loja",
      },
      edit: {
        success: "Loja editada com sucesso",
        error: "Não foi possível editar a loja",
      },
      toggleEnabled: {
        enableSuccess: "Loja ativada com sucesso",
        enableError: "Não foi possível ativar a loja",
        disableSuccess: "Loja desativada com sucesso",
        disableError: "Não foi possível desativar a loja",
      },
      name: "name",
      switch: "switch",
      zipcode: "zipcode",
      address: "address",
      phone: "phone",
      region: "region",
      about: "about",
      observations: "observations",
    },
  },

  variants: {
    menuTitle: "Grupos de opções",
    index: {
      menuTitle: "Lista de grupos de opções",
      title: "Grupos de opções",
      table: {
        name: "Nome",
        description: "Descrição",
        min_quantity: "Quantidade mínima",
        max_quantity: "Quantidade máxima",
        status: "Status",
      },
      toggleEnable: {
        setTrue: {
          title: "Desja ativar esta grupo de opção?",
          content:
            "Todos os produtos deste grupo de opção serão exibidos no client de pedidos",
        },
      },
      add: "Adicionar grupo de opção",
      edit: "Editar grupo de opção",
      editTabs: {
        main: "Editar grupo de opção",
        associatedProducts: "Produtos associados",
      },
    },
    add: {
      menuTitle: "Adicionar grupos de opções",
      title: "Adicionar grupos de opções",
    },
    edit: {
      menuTitle: "Editar grupo de opção",
      title: "Editar grupo de opção",
    },
    form: {
      add: {
        success: "Grupo de opção cadastrado com sucesso",
        error: "Não foi possível cadastrar o grupo de opção",
      },
      edit: {
        success: "Grupo de opção editado com sucesso",
        error: "Não foi possível editar o grupo de opção",
      },
      toggleEnabled: {
        enableSuccess: "Grupo de opção ativado com sucesso",
        enableError: "Não foi possível ativar a grupo de opção",
        disableSuccess: "Grupo de opção desativado com sucesso",
        disableError: "Não foi possível desativar o grupo de opção",
      },
      name: "Nome",
      description: "Descrição",
      min_quantity: "Quantidade mínima",
      max_quantity: "Quantidade máxima",
      is_enabled: "Status",
    },
  },

  options: {
    menuTitle: "Opções",
    index: {
      menuTitle: "Lista de opções",
      title: "Opções",
      table: {
        name: "Nome",
        description: "Descrição",
        price: "Preço",
        status: "Status",
      },
      toggleEnable: {
        setTrue: {
          title: "Desja ativar esta opção?",
          content:
            "Todos os produtos desta opção serão exibidos no client de pedidos",
        },
      },
      add: "Adicionar opção",
      edit: "Editar opção",
      editTabs: {
        main: "Editar opção",
        associatedProducts: "Produtos associados",
      },
    },
    add: {
      menuTitle: "Adicionar opções",
      title: "Adicionar opções",
    },
    edit: {
      menuTitle: "Editar opção",
      title: "Editar opção",
    },
    form: {
      add: {
        success: "Opção cadastrada com sucesso",
        error: "Não foi possível cadastrar a opção",
      },
      edit: {
        success: "Opção editada com sucesso",
        error: "Não foi possível editar a opção",
      },
      toggleEnabled: {
        enableSuccess: "Opção ativada com sucesso",
        enableError: "Não foi possível ativar a opção",
        disableSuccess: "Opção desativada com sucesso",
        disableError: "Não foi possível desativar a opção",
      },
      name: "Nome",
      description: "Descrição",
      price: "Preço",
      is_enabled: "Status",
    },
  },
});

export default getLocale;
