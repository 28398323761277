import React, { useState, useEffect } from "react";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Chip, SvgIconTypeMap, Button, withStyles } from "@material-ui/core";
import axios from "axios";
import { useRecoilState } from "recoil";
import { settings, locale, getHeaders } from "../../settings";
import ContentWrapper from "../../components/ContentWrapper";
import MainHeader from "../../components/MainHeader";
import MainTable from "../../components/MainTable";
import TitleHeader from "../../components/TitleHeader";
import DividerWrapper from "../../components/DividerWrapper";
import EditButton from "../../components/Buttons/EditButton";
import AddButton from "../../components/Buttons/AddButton";
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';

const ConfirmButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
  },
}))(Button);

const CancelButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
  },
}))(Button);



import UserAdd from "./UserAdd";
import UserEdit from "./UserEdit";

import {
  confirmationDialogState,
  dialogState,
  loadingOverlayState,
  snackBarState,
  handleErrorState,
} from "../../GlobalAtoms";

const Users: React.FC<{
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}> = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [editDialogStatus, setEditDialogStatus] = useState(false);
  const [editDialogData, setEditDialogData] = useState({});
  const [editKey, setEditKey] = useState<number | null>(null);

  // Recoil
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [confirmationDialog, setConfirmationDialogState] = useRecoilState(
    confirmationDialogState
  );
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);

  useEffect(() => {
    const url = `${settings().apiUrl}/api/buyings`;
    const headers = getHeaders();

    setData([    
      {
        id: '111',
        name: 'Usuário 1',
        email: 'usuario@email.com.br',
        admin: true,
        group_a: false,
        group_b: false,
        group_c: false,
        status: true,
      },
      {
        id: '222',
        name: 'Usuário 2',
        email: 'usuario@email.com.br',
        admin: false,
        group_a: false,
        group_b: true,
        group_c: true,
        status: true,
      },
      {
        id: '333',
        name: 'Usuário 3',
        email: 'usuario@email.com.br',
        admin: false,
        group_a: true,
        group_b: false,
        group_c: true,
        status: false,
      },
    ]);
    setLoading(false);

    /*axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoading(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );*/
  }, []);


  const openEditDialog = (rowData: {}, indexKey: number) => {
    console.log('hey');
    setDialogState({
      ...dialog,
      open: true,
      title: "Editar Usuário",
      content: editDialog,
      maxWidth: "xl",
      transition: "regular",
    })
  };

  const addDialog = <UserAdd setData={setData} data={data} />;

  const deleteItem = async (id: number) => {
    setLoadingOverlayState(true);
    let url = `${settings().apiUrl}/api/buyings/${id}`;
    let headers = getHeaders();

    await axios
      .delete(url, { headers })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/buyings`;
    headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoadingOverlayState(false);
        setSnackBar({
          open: true,
          type: "success",
          message: locale.products.form.edit.success,
        });
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  };

  const editDialog = <UserEdit />;

  const tableIndexes = locale.buyings.index.table;
  const columns = [
    {
      label: "ID",
      name: "id",
    },
    {
      label: "Nome",
      name: "name",
    },
    {
      label: "E-mail",
      name: "email",
    },
    {
      label: "Admin",
      name: "admin",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: any,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {value === true && <Chip label="X" style={{background: "#43a047", color: "#fff"}} />}
          </>
        ),
      },
    },
    {
      label: "Grupo A",
      name: "group_a",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: any,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {value === true && <Chip label="X" style={{background: "#43a047", color: "#fff"}} />}
          </>
        ),
      },
    },
    {
      label: "Grupo B",
      name: "group_b",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: any,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {value === true && <Chip label="X" style={{background: "#43a047", color: "#fff"}} />}
          </>
        ),
      },
    },
    {
      label: "Grupo C",
      name: "group_c",
            options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: any,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {value === true && <Chip label="X" style={{background: "#43a047", color: "#fff"}} />}
          </>
        ),
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: any,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {value === true && <Chip label="Ativo" style={{background: "#43a047", color: "#fff"}} />}
            {value === false && <Chip label="Inativo" style={{background: "#e53935", color: "#fff"}} />}
          </>
        ),
      },
    },
    {
      label: "Mudar Status",
      name: "status",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: any,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (

          <>
            {value === true && <CancelButton
                variant="contained"
                size="small"
                onClick={() =>
                  {return false;}
                }
              >
                <PowerSettingsNew style={{ fontSize: 18 }} />
              </CancelButton>}
            {value === false && <ConfirmButton
                variant="contained"
                size="small"
                onClick={() =>
                  {return false;}
                }
              >
                <PowerSettingsNew style={{ fontSize: 18 }} />
              </ConfirmButton>}
          </>         
        ),
      },
    },
    {
      label: " ",
      name: "id",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            <EditButton
              onClick={() =>
                openEditDialog(data[tableMeta.rowIndex], tableMeta.rowIndex)
              }
            />
          </>
        ),
      },
    },
  ];

  return (
    <>
      <ContentWrapper loading={loading}>
        <MainHeader
          title={<TitleHeader>{"Lista de Usuários"}</TitleHeader>}
          button={
            <AddButton
              onClick={() =>
                setDialogState({
                  ...dialog,
                  open: true,
                  title: "Cadastrar Novo Usuário",
                  content: addDialog,
                  maxWidth: "xl",
                  transition: "regular",
                })
              }
            >
              {"Cadastrar Novo Usuário"}
            </AddButton>
          }
        />
        <DividerWrapper />
        <MainTable data={data} columns={columns} />
      </ContentWrapper>
    </>
  );
};

export default Users;
