import React from "react";

// @material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Save from "@material-ui/icons/Save";
import blue from "@material-ui/core/colors/blue";
import { locale } from "../../settings";

const ColorButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: blue[600],
    "&:hover": {
      backgroundColor: blue[700],
    },
    width: "100%",
    padding: "8px 12px !important",
  },
}))(Button);

const SaveButton: React.FC<{
  children?: JSX.Element;
  disabled?: boolean;
  type?: string;
  onClick: () => void;
}> = (props: any) => (
  <ColorButton variant="contained" {...props}>
    {props.children ? <>{props.children}</> : <><Save style={{ fontSize: 18, marginRight: 10 }} /> {locale.miscellanea.save}</>}
  </ColorButton>
);

export default SaveButton;
